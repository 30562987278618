import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";

const navigation = [
  { name: "Projekty", href: "#", current: true },
  { name: "Kontakt", href: "#kontakt", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  return (
    <>
      <div className="min-h-full">
        <main>
          {/* <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
             <div class="flex -space-x-8">
              <div class="mix-blend-multiply bg-blue-400 p-12 rounded-full"> </div>
              <div class="mix-blend-multiply bg-pink-400 p-12 rounded-full"> </div>
            </div>
          </div> */}
          <div className="h-fit pb-8 bg-gradient-to-r shadow-xl text-white from-indigo-500 via-purple-500 to-pink-500 sm:px-0 mt-0 text-4xl">
            <Disclosure as="nav" className="shadow-xl mb-8">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-4">
                    <div className="flex h-16 items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="text-xl font-semibold px-3 py-1">
                            <span className="uppercase">
                              {/* border-b border-gray-400  */}
                              Adam Antoš
                            </span>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div className="ml-10 flex items-baseline space-x-4">
                            {navigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? "font-bold shadow-md bg-white/10"
                                    : "hover:bg-white/10 hover:shadow-md",
                                  "px-3 py-2 rounded text-white text-sm font-medium"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white/30 p-2 transtion duration-300 hover:bg-white/20 text-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="md:hidden">
                    <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                      {navigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "font-bold shadow-md bg-white/10"
                              : "text-gray-300 hover:shadow-md hover:bg-white/10",
                            "block px-3 py-2 rounded-md text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <div className="mx-auto max-w-7xl pb-32 px-6">
              <div>Adam Antoš.</div>
              <div className="mt-1">
                Full stack{" "}
                <span className="text-gray-300">software developer</span>
              </div>
            </div>
            <div className="mx-auto max-w-7xl flex flex-row justify-end gap-4">
              <img
                className="h-16"
                alt="React ikona"
                src="./pictures/react.webp"
              />
              <img
                className="h-16"
                alt="NestJS ikona"
                src="./pictures/nestjs.webp"
              />
              <img
                className="h-16"
                alt="C# ikona"
                src="./pictures/csharp.webp"
              />
              <img
                className="h-16"
                alt=".NET Core ikona"
                src="./pictures/netcore.webp"
              />
              <img
                className="h-16"
                alt="Python ikona"
                src="./pictures/python.webp"
              />
            </div>
          </div>
                    <div className="shadow-md">
            <div className="mx-automax-w-7xl py-6 px-6">
              <div>
                <h2 className="text-4xl text-center font-semibold mt-10 mb-5">
                  Projekty
                </h2>
                <div className="text-center text-2xl mb-10">
                  na kterých jsem se podílel
                </div>
                <div className="flex flex-row flex-wrap justify-center">
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-gray-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">iPes</div>
                    <div className="ml-7 mt-3">Interní personální systém</div>
                    <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/ipes.webp"
                      alt="iPes"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-blue-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Refsite kalkulačky
                    </div>
                    <div className="ml-7 mt-3">Chytré kalkulačky</div>
                    <a
                      className="ml-7 mt-3 text-blue-800 hover:underline"
                      href="https://newtools.refsite.info/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      newtools.refsite.info
                    </a>
                    <img
                      className="h-80 absolute right-0 bottom-0"
                      src="./pictures/refsite_calc.webp"
                      alt="Refsite kalkulačky"
                    />
                    {/* <div className="text-transparent cursor-pointer blur-none hover:blur-none rounded-xl hover:text-white flex justify-center hover:bg-gray-700/70 bg-tr w-96 h-96 top-0 absolute transition duration-500 ease">
                    <div className="mt-44 text-xl font-semibold">Refsite kalkulačky</div>
                  </div> */}
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-pink-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Web Heleny Králové
                    </div>
                    <div className="ml-7 mt-3">Web návrhářky interiérů</div>
                    <a
                      className="ml-7 mt-3 text-pink-800 hover:underline"
                      href="https://helena3dinterier.cz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      helena3dinterier.cz
                    </a>
                    <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/helena3dinterier.webp"
                      alt="Helena Králová"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-purple-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Hořický STAN
                    </div>
                    <div className="ml-7 mt-3">
                      Web politické strany pro komunální volby
                    </div>
                    <a
                      className="ml-7 mt-3 text-purple-800 hover:underline"
                      href="https://horicky-stan.cz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      horicky-stan.cz
                    </a>
                    <img
                      className="h-64 absolute right-0 bottom-0"
                      src="./pictures/horicky-stan.webp"
                      alt="Hořický STAN"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-gray-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">MMNVS</div>
                    <div className="ml-7 mt-3 text-wrap">
                      Monitoring a management napájení
                      <br /> virtuálních serverů
                    </div>
                    <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/mmnvs.webp"
                      alt="MMNVS"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Co umim */}
          {/* <div className="shadow-md">
            <div className="mx-automax-w-7xl py-6 px-6">
              <div>
                <h2 className="text-4xl text-center font-semibold mt-10 mb-5">
                  Co umím
                </h2>
                <div className="flex flex-row flex-wrap justify-center">
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-gray-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">Webové stránky</div>
                    <div className="ml-7 mt-3">Navrhnu vám </div>
                   <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/ipes.webp"
                      alt="iPes"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-blue-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Refsite kalkulačky
                    </div>
                    <div className="ml-7 mt-3">Chytré kalkulačky</div>
                    <a
                      className="ml-7 mt-3 text-blue-800 hover:underline"
                      href="https://newtools.refsite.info/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      newtools.refsite.info
                    </a>
                    <img
                      className="h-80 absolute right-0 bottom-0"
                      src="./pictures/refsite_calc.webp"
                      alt="Refsite kalkulačky"
                    />
                    <div className="text-transparent cursor-pointer blur-none hover:blur-none rounded-xl hover:text-white flex justify-center hover:bg-gray-700/70 bg-tr w-96 h-96 top-0 absolute transition duration-500 ease">
                    <div className="mt-44 text-xl font-semibold">Refsite kalkulačky</div>
                  </div>
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-pink-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Web Heleny Králové
                    </div>
                    <div className="ml-7 mt-3">Web návrhářky interiérů</div>
                    <a
                      className="ml-7 mt-3 text-pink-800 hover:underline"
                      href="https://helena3dinterier.cz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      helena3dinterier.cz
                    </a>
                    <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/helena3dinterier.webp"
                      alt="Helena Králová"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-purple-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">
                      Hořický STAN
                    </div>
                    <div className="ml-7 mt-3">
                      Web politické strany pro komunální volby
                    </div>
                    <a
                      className="ml-7 mt-3 text-purple-800 hover:underline"
                      href="https://horicky-stan.cz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      horicky-stan.cz
                    </a>
                    <img
                      className="h-64 absolute right-0 bottom-0"
                      src="./pictures/horicky-stan.webp"
                      alt="Hořický STAN"
                    />
                  </div>
                  <div className="hover:scale-105 rounded-lg relative m-6 shadow-lg bg-gray-100 hover:shadow-xl mb-10  transition duration-500 ease min-w-[22rem] w-[22rem] h-96">
                    <div className="text-xl font-semibold mt-7 ml-7">MMNVS</div>
                    <div className="ml-7 mt-3 text-wrap">
                      Monitoring a management napájení
                      <br /> virtuálních serverů
                    </div>
                    <img
                      className="h-56 absolute right-0 bottom-0"
                      src="./pictures/mmnvs.webp"
                      alt="MMNVS"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl py-6 pb-16 px-6">
              <div>
                <h2 className="text-4xl text-white text-center font-semibold mt-10 mb-5">
                  Kontakt
                </h2>
                <div className="flex flex-row justify-center">
                  <div className="bg-gray-800 flex flex-row h-fit w-full rounded-md">
                    <img
                      className="rounded-md shadow-md w-36 h-36"
                      alt="Adam Antoš"
                      src="./pictures/adamantos.webp"
                    />
                    <div className="m-3 text-white">
                      <div className="font-semibold">Adam Antoš</div>
                      <div>IČO: 18021107</div>
                      <div>E-mail: <a className="hover:underline" href="mailto:dev@adamantos.eu">dev@adamantos.eu</a></div>
                      <div>Tel.: <a className="hover:underline" href="tel:+420722108500">722108500</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="shadow-lg">
            <div className="mx-auto max-w-7xl py-10 pb-16 px-6">
              <div className="md:flex">
                <div id="kontakt" className="w-4/12">
                  <h2 className="text-4xl font-bold">Kontakt</h2>
                  {/* <div className="text-md">xxx</div> */}
                </div>
                <div className="w-8/12">
                  <div className="flex">
                    <img className="rounded-md hidden md:block w-56" alt="Adam Antoš" src="./pictures/adamantos.webp" />
                    <div className="m-3 ml-10 text-gray-600 text-lg">
                      <div className="font-semibold text-black mb-4">Adam Antoš</div>
                      <div>IČO: 18021107</div>
                      <div>E-mail: <a className="hover:underline" href="mailto:dev@adamantos.eu">dev@adamantos.eu</a></div>
                      <div>Tel.: <a className="hover:underline" href="tel:+420722108500">722108500</a></div>
                      <div className="flex mt-4">
                        <a className="px-2" href="https://github.com/antos-adam" target="_blank" rel="noreferrer"><FaGithub className="hover:fill-black cursor-pointer" /></a>
                        <a className="px-2" href="https://www.linkedin.com/in/adam-antoš-2bb343244/" target="_blank" rel="noreferrer"><FaLinkedin className="hover:fill-black cursor-pointer" /></a>
                        <a className="px-2" href="mailto:dev@adamamtos.eu"><FaEnvelope className="hover:fill-black cursor-pointer" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
